/* Popup.css */

/* Popup Overlay */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Popup Content */
  .popup-content {
    max-width: 600px; /* Limit the width of the form */
    margin: 20px auto; /* Center the form on the page */
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: left; /* Left-align text inside the popup */
  }
  
  /* Form Group Styling for Popup */
  .form-group {
    margin-bottom: 15px; /* Space between fields */
    display: flex; /* Use flexbox for alignment */
    flex-direction: column; /* Stack label and input vertically */
  }
  
  .form-group label {
    margin-bottom: 5px; /* Space between label and input */
    font-weight: bold; /* Make labels bold */
  }
  
  input, 
  textarea {
    width: 100%; /* Full width for inputs */
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    box-sizing: border-box; /* Include padding in width calculation */
  }
  
  textarea {
    resize: none; /* Prevent resizing */
  }
  
  /* Popup Buttons */
  .popup-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  /* Responsive Design for Popup */
  @media (max-width: 900px) {
    .popup-content {
      width: 90%; /* Make the popup width responsive */
      margin: 10px; /* Adjust margins for smaller screens */
    }
  }
  