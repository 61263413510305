.footer-section {
  background-color: #2c3e50; /* Dark background */
  color: #add8e6; /* Light blue text color */
  padding: 2px 5px; /* Reduced top padding to decrease height */
  width: 100%;
  box-sizing: border-box;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.footer-left, .footer-center, .footer-right {
  flex-basis: 30%;
  text-align: left;
  margin: 2px 0; /* Reduced margin to decrease height */
}

.footer-center {
  text-align: center;
}

.footer-right {
  text-align: right;
}

.footer-left h3, .footer-right h3 {
  font-size: 1.1rem; /* Further reduced font size */
  margin-bottom: 2px; /* Reduced margin for titles */
}

.footer-left p, .footer-right p {
  font-size: 0.8rem; /* Further reduced font size */
  line-height: 1.4; /* Reduced line height */
  margin: 0;
}

.footer-right a, .footer-left a {
  color: #add8e6; /* Light blue color for links */
  text-decoration: none;
}

.footer-right a:hover, .footer-left a:hover {
  text-decoration: underline;
}

.footer-container p {
  margin: 0;
}

.social-media {
  margin-top: 2px; /* Reduced margin */
}

.social-icon {
  color: orange; /* Changed social icon color to orange */
  margin-right: 8px; /* Further reduced margin */
  font-size: 0.9rem; /* Further reduced icon size */
  transition: color 0.3s ease;
}

.social-icon:hover {
  color: darkorange; /* Darker shade on hover */
}

.footer-right .social-icon:last-child {
  margin-right: 0;
}

/* New styles for footer icons */
.footer-icons {
  display: flex; /* Maintain horizontal layout */
  padding: 2px 0; /* Reduced padding to prevent extra height */
  margin: 0; /* Ensure no margin */
}

.footer-icon {
  margin: 0 10px; /* Space out the icons */
  font-size: 18px; /* Further reduced icon size */
  color: orange; /* Changed footer icon color to orange */
  text-decoration: none; /* Remove underline */
}

.footer-icon:hover {
  color: darkorange; /* Darker shade on hover */
}

.client-details {
  display: inline-block; /* Ensure content is on the same line */
  font-size: 14px; /* Adjust font size if needed */
}

.client-details a {
  color: #007bff; /* Link color */
  text-decoration: none; /* Remove underline */
}

.client-details a:hover {
  text-decoration: underline; /* Underline on hover */
}

.footer-center {
  text-align: center; /* Center align the text */
}

.footer-center p {
  margin: 0; /* Reset margins */
}

.footer-center .client-details {
  margin-top: 20px; /* Adjust the space between the two lines */
}
