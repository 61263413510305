.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Dark overlay background */
  display: flex;
  justify-content: center; /* Horizontally centers the modal */
  align-items: center; /* Vertically centers the modal */
  z-index: 1000; /* Ensures the modal is above other content */
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 700px; /* Adjust the width as needed */
  max-width: 90%; /* Ensure responsiveness */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5); /* Subtle shadow for depth */
}

.close-button {
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  float: right;
}

.close-button:hover {
  color: red; /* Color change on hover */
}
