/* src/styles/header.css */
.header-section {
    text-align: center;
    font-size: 14px;
  }
  
  .contact-button, .download-button {
    margin: 10px;
    padding: 10px 20px;
    background-color: #007bff; /* Blue color */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .contact-button:hover, .download-button:hover {
    background-color: #0056b3; /* Darker blue */
  }
  