/* Landing.css */

/* Main Landing Page Layout */
.landing-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  overflow: hidden; /* Prevent horizontal scrolling */
}

/* Pricing and Features Section */
.pricing-features-section {
  padding: 30px 20px;
  text-align: center;
  background-color: transparent; /* Make the background transparent */
  flex: 1; /* Allow the section to grow */
  overflow-y: auto; /* Enable vertical scrolling if content overflows */
  max-height: 100%; /* Fit section within the screen height */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center contents horizontally */
  justify-content: center; /* Center contents vertically */
}

/* Pricing Grid Layout */
.pricing-grid {
  display: grid;
  grid-auto-flow: column; /* Arrange items in a single horizontal row */
  gap: 20px; /* Space between the pricing cards */
  overflow-x: auto; /* Allow horizontal scrolling if necessary */
  padding: 20px; /* Add padding around the grid */
}

.pricing-card {
  min-width: 250px; /* Set a minimum width for each pricing card */
  background: linear-gradient(145deg, #ffffff, #e6e9f0);
  border-radius: 12px;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: all 0.3s ease;
}

.pricing-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

/* Responsive Design for Smaller Screens */
@media (max-width: 900px) {
  .pricing-grid {
    display: flex;
    flex-wrap: nowrap; /* Prevent items from wrapping */
    overflow-x: scroll; /* Enable horizontal scrolling for small screens */
  }
}



.pricing-card {
  background: linear-gradient(145deg, #ffffff, #e6e9f0);
  border-radius: 12px;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: all 0.3s ease;
}

.pricing-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.pricing-card h3 {
  font-size: 1.5rem;
  margin-bottom: 10px; /* Further reduced margin */
}

.pricing-card .price {
  font-size: 1.6rem; /* Slightly reduced font size */
  color: #ff7a59;
  font-weight: bold;
  margin-bottom: 15px; /* Further reduced margin */
}

.features-list {
  list-style: none;
  padding: 0;
  box-sizing: border-box;
}

.features-list li {
  padding: 6px 0; /* Further reduced padding */
  font-size: 0.9rem; /* Further reduced font size */
  color: #555;
  display: flex;
  align-items: center;
}

.features-list i {
  margin-right: 5px; /* Further reduced margin */
  color: #4CAF50;
}

.features-list li:not(:last-child) {
  border-bottom: 1px solid #f0f0f0;
}

/* Responsive Design */
@media (max-width: 900px) {
  .pricing-grid {
    grid-template-columns: 1fr; /* Stack items in one column */
  }
}

.seo-info {
  margin: 20px 0; /* Adjust margins as needed */
  font-size: 1.2em; /* Change font size */
  color: #333; /* Adjust text color */
  display: flex; /* Align items in a row */
  align-items: center; /* Center icon and text vertically */
}

@keyframes blink {
  0%, 100% {
    opacity: 1; /* Fully visible */
  }
  50% {
    opacity: 0; /* Fully transparent */
  }
}

.seo-icon {
  margin-right: 10px; /* Space between icon and text */
  color: #ff6600; /* Change icon color as needed */
  animation: blink 1s infinite; /* Blink effect */
}

.note-info {
  display: flex;
  align-items: center; /* Center the icon and text */
  margin: 20px 0; /* Adjust margin as needed */
}

.note-icon {
  margin-right: 10px; /* Space between icon and text */
  color: #ff6600; /* Change icon color as needed */
}

/* Blinking effect for the icon */
.blinking-icon {
  animation: blink 1s infinite; /* Blink effect */
}

@keyframes blink {
  0%, 100% {
    opacity: 1; /* Fully visible */
  }
  50% {
    opacity: 0; /* Fully transparent */
  }
}

/* Responsive Design for Smaller Screens */
@media (max-width: 900px) {
  .pricing-grid {
    display: flex;
    flex-direction: column; /* Stack cards vertically */
    gap: 15px; /* Adjust gap between cards */
    overflow-x: scroll; /* Enable horizontal scrolling for small screens */
  }
}

/* Scrollbar Styling for Better Visibility */
.landing-page, .pricing-features-section {
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: #888 #f0f0f0; /* Track and thumb color */
}

.landing-page::-webkit-scrollbar, .pricing-features-section::-webkit-scrollbar {
  width: 10px; /* Width of scrollbar */
}

.landing-page::-webkit-scrollbar-thumb, .pricing-features-section::-webkit-scrollbar-thumb {
  background-color: #888; /* Scrollbar thumb color */
  border-radius: 10px;
}

/* Ensure Responsive Behavior on Tablets and Mobile Devices */
@media (max-width: 768px) {
  .pricing-grid {
    display: flex;
    flex-direction: column; /* Stack cards vertically on small screens */
    align-items: center; /* Center cards horizontally */
  }

  .seo-info, .note-info {
    flex-direction: column; /* Stack icon and text vertically */
    text-align: center;
  }

  .pricing-card {
    min-width: 100%; /* Make pricing cards full width */
  }

  .landing-page {
    padding: 10px; /* Add extra padding on small screens */
  }
}

/* Further Adjustments for Mobile Devices (max-width 480px) */
@media (max-width: 480px) {
  .pricing-card h3 {
    font-size: 1.2rem; /* Adjust heading font size */
  }

  .pricing-card .price {
    font-size: 1.4rem; /* Adjust price font size */
  }

  .seo-info, .note-info {
    margin: 15px 0; /* Reduce margins */
    font-size: 1rem; /* Adjust font size */
  }
}